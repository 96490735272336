import { makeStyles } from "@material-ui/core";
import { useContext } from "react";
import SimpleDashboard from "../SimpleDashboard/SimpleDashboard";
import UserContext from "../UserContext";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
    },
}));

export const Dashboard = () => {
    const classes = useStyles();
    const { user } = useContext(UserContext);

    return <>{user && user.classroom?.id && <SimpleDashboard />}</>;
};

export default Dashboard;
