// import {
//     Box,
//     Button,
//     Checkbox,
//     Grid,
//     IconButton,
//     Menu,
//     MenuItem,
//     Table,
//     TableBody,
//     TableCell,
//     TableContainer,
//     TableHead,
//     TablePagination,
//     TableRow,
//     TextField,
//     Tooltip,
//     Typography,
//     useTheme,
// } from "@material-ui/core";
// import { ViewColumn, ViewColumnOutlined } from "@material-ui/icons";
// import { Alert } from "@material-ui/lab";
// import ClearAllIcon from "@mui/icons-material/ClearAll";
// import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
// import { PaymentService } from "../../../services/PaymentService";
// import { Autocomplete } from "../../Autocomplete/Autocomplete";
// import { currencyFormatter } from "../../constants";
// import PrintTable from "../../export/PrintTable";
// import { ReportHeader } from "../../settings/Header/ReportHeader";
// import UserContext from "../../UserContext";
// const PaymentRecord = () => {
//     const [filter, setFilter] = useState({
//         search: "",
//         seachForShowing: "",
//         clientType: "",
//         client: "",
//         bank: "",
//         apply: 0,
//         startDate: "",
//         endDate: "",
//         status: "",
//         city: "",
//     });

//     const [showColumns, setShowColumns] = useState(false);
//     const [_updateSelected, setUpdateSelected] = useState<any>();
//     const showColumnsBtn = useRef<HTMLButtonElement | null>(null);
//     const [columnsVisibility, setColumnsVisibility] = useState<any>({
//         SrNo: true,
//         Client: true,
//         Phone: true,
//         City: true,
//         TotalDebit: true,
//         TotalCredit: true,
//         TotalAdjustment: true,
//         TotalAdvance: true,
//         TotalRefund: true,
//         TotalBalance: true,
//         Pay: true,
//     });
//     const printRef = useRef<HTMLDivElement | null>(null);
//     const [totalPayments, setTotalPayments] = useState<any>();

//     const [collections, setCollections] = useState<{
//         [key: number]: any;
//     }>({});

//     const [printMode, setPrintMode] = useState(false);
//     const [totalHistory, setTotalHistory] = useState<any>();
//     const [count, setCount] = useState(0);
//     const [pagination, setPagination] = useState({
//         page: 0,
//         count: 0,
//         limit: 10,
//     });
//     const { settings } = useContext(UserContext);
//     const [clearFilters, setClearFilters] = useState(false);
//     const theme = useTheme();

//     const [record, setTotalRecord] = useState<any>();
//     const ref = useRef(0);

//     const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
//         ref.current++;
//         const newRef = ref.current;
//         const { value } = e.target;

//         setFilter({ ...filter, seachForShowing: value });

//         setTimeout(() => {
//             if (newRef === ref.current) {
//                 setFilter((curr: any) => ({
//                     ...curr,
//                     ["search"]: value,
//                 }));

//                 ref.current = 0;
//             }
//         }, 1000);
//     };

//     const handleChange = (ev: ChangeEvent<any>, id: number) => {
//         setCollections(() => ({
//             ...collections,
//             [id]: { ...collections[id], [ev.target.name]: ev.target.value },
//         }));
//     };

//     const handleColumnVisibilityChange = (column: string) => {
//         setColumnsVisibility((prev: any) => {
//             const newVisibility = {
//                 ...prev,
//                 [column]: !prev[column],
//             };

//             localStorage.setItem(
//                 "totalCreditDebitRecord",
//                 JSON.stringify(newVisibility)
//             );

//             return newVisibility;
//         });
//     };

//     const getTotalRecord = async () => {
//         const [data, err] = await PaymentService.getTotalRecord(
//             pagination.page + 1,
//             pagination.limit,
//             {
//                 client: filter.client ? filter.client : null,
//                 search: filter.search ? filter.search : null,
//                 city: filter.city ? filter.city : null,
//                 ClientType: filter.clientType ? filter.clientType : null,
//             }
//         );

//         if (data.rows.length) {
//             setTotalRecord(data.rows);
//             setPagination({
//                 ...pagination,
//                 count: data?.count ?? 0,
//             });
//         } else {
//             setTotalHistory([]);
//             setTotalRecord([]);
//         }
//     };

//     const handleFilterClear = () => {
//         setClearFilters(!clearFilters);
//         setFilter({
//             search: "",
//             seachForShowing: "",
//             clientType: "",
//             client: "",
//             bank: "",
//             apply: 0,
//             startDate: "",
//             endDate: "",
//             status: "",
//             city: "",
//         });

//         setCount(count + 1);
//     };

//     useEffect(() => {
//         getTotalRecord();
//     }, [filter.apply, filter.search, count, pagination.page, pagination.limit]);

//     useEffect(() => {
//         const savedColumns = localStorage.getItem("totalCreditDebitRecord");

//         if (savedColumns) {
//             setColumnsVisibility(JSON.parse(savedColumns));
//         } else {
//             setColumnsVisibility({
//                 SrNo: true,
//                 Client: true,
//                 Phone: true,
//                 City: true,
//                 TotalDebit: true,
//                 TotalCredit: true,
//                 TotalAdjustment: true,
//                 TotalAdvance: true,
//                 TotalBalance: true,
//                 TotalRefund: true,
//                 Pay: true,
//             });
//         }
//     }, []);

//     useEffect(() => {
//         // Calculate the sum of all payments
//         const sum =
//             Object.values(collections)?.reduce((acc: any, item: any) => {
//                 const payment = parseFloat(item.name) || 0;
//                 return acc + payment;
//             }, 0) || 0;

//         setTotalPayments(sum);
//     }, [Object.values(collections)]);

//     return (
//         <Grid container spacing={1} style={{ padding: "2rem" }}>
//             <Grid container spacing={1} style={{ padding: "2rem" }}>
//                 <Grid item xs={4}>
//                     <Autocomplete
//                         api="/city/get-All"
//                         setOutput={(ci) =>
//                             setFilter({
//                                 ...filter,
//                                 city: ci?.id || "",
//                             })
//                         }
//                         label="City"
//                         labelKey="name"
//                         textFieldProps={{ variant: "outlined", size: "small" }}
//                         clear={clearFilters}
//                     />
//                 </Grid>
//                 <Grid item xs={4}>
//                     <Autocomplete
//                         labelKey="name"
//                         label="Client Type"
//                         api="/clientType/get-All"
//                         setOutput={(r) =>
//                             setFilter({
//                                 ...filter,
//                                 clientType: r?.id || null,
//                             })
//                         }
//                         clear={clearFilters}
//                         textFieldProps={{
//                             variant: "outlined",
//                             size: "small",
//                         }}
//                     />
//                 </Grid>

//                 <Grid item xs={4}>
//                     <Autocomplete
//                         label="Client"
//                         api="/employee/all-employees"
//                         labelKey="name"
//                         setOutput={(opt) => {
//                             setFilter({
//                                 ...filter,
//                                 client: opt?.id ?? "",
//                             });
//                         }}
//                         textFieldProps={{ variant: "outlined", size: "small" }}
//                         apiParams={{
//                             clientType: filter.clientType
//                                 ? filter.clientType
//                                 : null,
//                             city: filter.city ? filter.city : null,
//                         }}
//                         clear={clearFilters}
//                     />
//                 </Grid>

//                 <Grid item xs={4}>
//                     <Button
//                         variant="contained"
//                         fullWidth
//                         color="primary"
//                         onClick={() =>
//                             setFilter({
//                                 ...filter,
//                                 apply: filter.apply + 1,
//                             })
//                         }
//                         size="small"
//                         style={{ height: "80%" }}
//                     >
//                         Apply filter
//                     </Button>
//                 </Grid>

//                 <Grid item xs={4}>
//                     <Tooltip title="Clear Filters">
//                         <IconButton onClick={handleFilterClear} color="primary">
//                             <ClearAllIcon />
//                         </IconButton>
//                     </Tooltip>
//                 </Grid>
//             </Grid>

//             <Grid
//                 item
//                 xs={12}
//                 style={{
//                     borderBottom: `1px solid ${theme.palette.divider}`,
//                 }}
//             ></Grid>

//             <Grid
//                 container
//                 style={{ padding: "2rem", justifyContent: "space-between" }}
//             >
//                 <Grid
//                     item
//                     xs={4}
//                     style={{ display: "flex", alignItems: "center" }}
//                 >
//                     {Object.keys(filter).includes("search") && (
//                         <Grid item style={{ flex: "1" }}>
//                             <TextField
//                                 fullWidth
//                                 placeholder="Search"
//                                 variant="outlined"
//                                 value={filter.seachForShowing}
//                                 onChange={handleSearch}
//                                 label="Search"
//                                 size="small"
//                             />
//                         </Grid>
//                     )}

//                     <Tooltip title="Select Columns">
//                         <IconButton
//                             ref={showColumnsBtn}
//                             onClick={() => setShowColumns(true)}
//                         >
//                             {showColumns ? (
//                                 <ViewColumn
//                                     htmlColor={theme.palette.primary.main}
//                                 />
//                             ) : (
//                                 <ViewColumnOutlined />
//                             )}
//                         </IconButton>
//                     </Tooltip>

//                     <Menu
//                         open={showColumns}
//                         anchorEl={showColumnsBtn.current}
//                         onClose={() => setShowColumns(false)}
//                         elevation={4}
//                         variant="menu"
//                     >
//                         {Object.keys(columnsVisibility).map((col) => (
//                             <MenuItem key={col} style={{ paddingLeft: 0 }}>
//                                 <Checkbox
//                                     size="small"
//                                     checked={columnsVisibility[col]} // Checked if the column is visible
//                                     onChange={() =>
//                                         handleColumnVisibilityChange(col)
//                                     }
//                                     color="primary"
//                                 />

//                                 <Typography>{col}</Typography>
//                             </MenuItem>
//                         ))}
//                     </Menu>
//                 </Grid>
//                 <Grid item xs={4}>
//                     <Box
//                         style={{
//                             display: "flex",
//                             justifyContent: "space-between",
//                         }}
//                     >
//                         <PrintTable
//                             componentRef={printRef}
//                             setPrintMode={setPrintMode}
//                         />

//                         <TablePagination
//                             component="div"
//                             onPageChange={(ev, page) =>
//                                 setPagination({ ...pagination, page: page })
//                             }
//                             onRowsPerPageChange={(ev) =>
//                                 setPagination({
//                                     ...pagination,
//                                     limit: parseInt(ev.target.value),
//                                     page: 0,
//                                 })
//                             }
//                             count={pagination.count}
//                             page={pagination.page}
//                             rowsPerPage={pagination.limit}
//                         />
//                     </Box>
//                 </Grid>
//             </Grid>

//             {record && record.length > 0 ? (
//                 <Grid item xs={12} ref={printRef}>
//                     {printMode && (
//                         <ReportHeader
//                             orgInfo={(() => {
//                                 let reportsHeader = settings["reports-header"];
//                                 try {
//                                     reportsHeader = JSON.parse(reportsHeader);
//                                 } catch (e) {
//                                     reportsHeader = null;
//                                 }

//                                 return (
//                                     reportsHeader || {
//                                         name: {
//                                             value: "Account Book",
//                                             selected: true,
//                                         },
//                                         email: {
//                                             value: "",
//                                             selected: false,
//                                         },
//                                         contactNo: {
//                                             value: "",
//                                             selected: false,
//                                         },
//                                         address: {
//                                             value: "",
//                                             selected: false,
//                                         },
//                                         logoPos: {
//                                             value: "left",
//                                             selected: false,
//                                         },
//                                     }
//                                 );
//                             })()}
//                         />
//                     )}
//                     <TableContainer>
//                         <Table size="medium">
//                             <TableHead>
//                                 <TableRow style={{ whiteSpace: "nowrap" }}>
//                                     {columnsVisibility.SrNo && (
//                                         <TableCell>SR No</TableCell>
//                                     )}
//                                     {columnsVisibility.Client && (
//                                         <TableCell>Client Name</TableCell>
//                                     )}

//                                     {columnsVisibility.Phone && (
//                                         <TableCell>Phone</TableCell>
//                                     )}

//                                     {columnsVisibility.City && (
//                                         <TableCell>City</TableCell>
//                                     )}
//                                     {columnsVisibility.TotalDebit && (
//                                         <TableCell>Total Debit</TableCell>
//                                     )}

//                                     {columnsVisibility.TotalRefund && (
//                                         <TableCell>Total Refund</TableCell>
//                                     )}
//                                     {columnsVisibility.TotalCredit && (
//                                         <TableCell>Total Credit</TableCell>
//                                     )}
//                                     {columnsVisibility.TotalAdjustment && (
//                                         <TableCell>Total Adjustment</TableCell>
//                                     )}

//                                     {columnsVisibility.TotalBalance && (
//                                         <TableCell>Total Balance</TableCell>
//                                     )}
//                                     {columnsVisibility.TotalAdvance && (
//                                         <TableCell>Total Advance</TableCell>
//                                     )}

//                                     {columnsVisibility.Pay && (
//                                         <TableCell>Pay</TableCell>
//                                     )}
//                                 </TableRow>
//                             </TableHead>

//                             <TableBody>
//                                 {record?.map((item: any, idx: number) => (
//                                     <TableRow>
//                                         {columnsVisibility.SrNo && (
//                                             <TableCell>{idx + 1}</TableCell>
//                                         )}

//                                         {columnsVisibility.Client && (
//                                             <TableCell>{item.client}</TableCell>
//                                         )}

//                                         {columnsVisibility.Phone && (
//                                             <TableCell>
//                                                 {item.Phone ? (
//                                                     item.Phone
//                                                 ) : (
//                                                     <Typography
//                                                         style={{
//                                                             color: theme.palette
//                                                                 .divider,
//                                                         }}
//                                                     >
//                                                         N/A
//                                                     </Typography>
//                                                 )}
//                                             </TableCell>
//                                         )}

//                                         {columnsVisibility.City && (
//                                             <TableCell>
//                                                 {item?.City ? (
//                                                     item?.City
//                                                 ) : (
//                                                     <Typography
//                                                         style={{
//                                                             color: theme.palette
//                                                                 .divider,
//                                                         }}
//                                                     >
//                                                         N/A
//                                                     </Typography>
//                                                 )}
//                                             </TableCell>
//                                         )}

//                                         {columnsVisibility.TotalDebit && (
//                                             <TableCell>
//                                                 {currencyFormatter(
//                                                     item.TotalDebit
//                                                 )}
//                                             </TableCell>
//                                         )}

//                                         {columnsVisibility.TotalRefund && (
//                                             <TableCell>
//                                                 {currencyFormatter(
//                                                     item.TotalRefund
//                                                 )}
//                                             </TableCell>
//                                         )}

//                                         {columnsVisibility.TotalCredit && (
//                                             <TableCell>
//                                                 {currencyFormatter(
//                                                     item.TotalCredit
//                                                 )}
//                                             </TableCell>
//                                         )}

//                                         {columnsVisibility.TotalAdjustment && (
//                                             <TableCell>
//                                                 {currencyFormatter(
//                                                     item.TotalAdjustment
//                                                 )}
//                                             </TableCell>
//                                         )}

//                                         {columnsVisibility.TotalBalance && (
//                                             <TableCell>
//                                                 {currencyFormatter(
//                                                     item.TotalBalance
//                                                 )}
//                                             </TableCell>
//                                         )}

//                                         {columnsVisibility.TotalAdvance && (
//                                             <TableCell>
//                                                 {currencyFormatter(
//                                                     item.TotalAdvance
//                                                 )}
//                                             </TableCell>
//                                         )}

//                                         {columnsVisibility.Pay && (
//                                             <TableCell>
//                                                 <TextField
//                                                     style={{
//                                                         minWidth: "220px",
//                                                     }}
//                                                     multiline
//                                                     size="small"
//                                                     name="name"
//                                                     label="Pay"
//                                                     value={
//                                                         collections[idx]?.name
//                                                     }
//                                                     onChange={(ev) =>
//                                                         handleChange(ev, idx)
//                                                     }
//                                                     variant="outlined"
//                                                 />
//                                             </TableCell>
//                                         )}
//                                     </TableRow>
//                                 ))}
//                             </TableBody>
//                         </Table>
//                     </TableContainer>

//                     <Grid
//                         item
//                         xs={12}
//                         style={{
//                             paddingTop: "1rem",
//                             borderTop: `1px solid ${theme.palette.divider}`,
//                         }}
//                     >
//                         <Typography variant="h6" align="right">
//                             Total Payments: {currencyFormatter(totalPayments)}
//                         </Typography>
//                     </Grid>
//                 </Grid>
//             ) : (
//                 <Alert severity="info" style={{ width: "100%" }}>
//                     No payment record found
//                 </Alert>
//             )}
//         </Grid>
//     );
// };

// export default PaymentRecord;

import {
    Box,
    Button,
    Checkbox,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from "@material-ui/core";
import { ViewColumn, ViewColumnOutlined } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { PaymentService } from "../../../services/PaymentService";
import { Autocomplete } from "../../Autocomplete/Autocomplete";
import { currencyFormatter } from "../../constants";
import PrintTable from "../../export/PrintTable";
import { ReportHeader } from "../../settings/Header/ReportHeader";
import UserContext from "../../UserContext";

const PaymentRecord = () => {
    const [filter, setFilter] = useState({
        search: "",
        seachForShowing: "",
        clientType: "",
        client: "",
        bank: "",
        apply: 0,
        startDate: "",
        endDate: "",
        status: "",
        city: "",
    });

    const [showColumns, setShowColumns] = useState(false);
    const [_updateSelected, setUpdateSelected] = useState<any>();
    const showColumnsBtn = useRef<HTMLButtonElement | null>(null);
    const [columnsVisibility, setColumnsVisibility] = useState<any>({
        SrNo: true,
        Client: true,
        Phone: true,
        City: true,
        TotalDebit: true,
        TotalRefund: true,
        TotalCredit: true,
        TotalAdjustment: true,
        TotalBalance: true,
        TotalAdvance: true,

        Pay: true,
        DetailLeasure: true,
    });
    const [creditDebitData, setCreditDebitData] = useState<any>();

    const printRef = useRef<HTMLDivElement | null>(null);
    const [totalPayments, setTotalPayments] = useState<any>();

    const [collections, setCollections] = useState<{
        [key: number]: any;
    }>({});

    // const createSummary = (cols: any) => {
    //     const summaryRow = ["Total"];

    //     let TotalCredit = 0;
    //     let TotalDebit = 0;
    //     let TotalAdjustment = 0;
    //     let TotalAdvance = 0;
    //     let TotalRefund = 0;

    //     if (record) {
    //         for (const row of record) {
    //             TotalCredit += row["TotalCredit"];
    //             TotalDebit += row["TotalDebit"];
    //             TotalAdjustment += row["TotalAdjustment"];
    //             TotalAdvance += row["TotalAdvance"];
    //             TotalRefund += row["TotalRefund"];
    //         }
    //     }

    //     for (const col of [
    //         "TotalCredit",
    //         "TotalDebit",
    //         "TotalAdjustment",
    //         "TotalAdvance",
    //         "TotalRefund",
    //     ]) {
    //         switch (col) {
    //             case "TotalCredit":
    //                 summaryRow.push(currencyFormatter(TotalCredit));
    //                 break;

    //             case "TotalDebit":
    //                 summaryRow.push(currencyFormatter(TotalDebit));
    //                 break;

    //             case "TotalAdjustment":
    //                 summaryRow.push(currencyFormatter(TotalAdjustment));
    //                 break;

    //             case "TotalAdvance":
    //                 summaryRow.push(currencyFormatter(TotalAdvance));
    //                 break;

    //             case "TotalRefund":
    //                 summaryRow.push(currencyFormatter(TotalAdvance));
    //                 break;

    //             default:
    //                 summaryRow.push("");
    //         }
    //     }

    //     return summaryRow;
    // };

    const createSummary = (columnsVisibility: any) => {
        const summaryRow: any[] = [];

        let TotalCredit = 0;
        let TotalDebit = 0;
        let TotalAdjustment = 0;
        let TotalAdvance = 0;
        let TotalRefund = 0;
        let TotalBalance = 0;

        if (record) {
            for (const row of record) {
                TotalDebit += row["TotalDebit"];
                TotalRefund += row["TotalRefund"];
                TotalCredit += row["TotalCredit"];
                TotalAdjustment += row["TotalAdjustment"];
                TotalBalance += row["TotalBalance"];
                TotalAdvance += row["TotalAdvance"];
            }
        }

        // Add a placeholder or appropriate value for each visible column
        for (const col of Object.keys(columnsVisibility)) {
            if (columnsVisibility[col]) {
                switch (col) {
                    case "TotalDebit":
                        summaryRow.push(currencyFormatter(TotalDebit));
                        break;
                    case "TotalRefund":
                        summaryRow.push(currencyFormatter(TotalRefund));
                        break;
                    case "TotalCredit":
                        summaryRow.push(currencyFormatter(TotalCredit));
                        break;
                    case "TotalAdjustment":
                        summaryRow.push(currencyFormatter(TotalAdjustment));
                        break;

                    case "TotalBalance":
                        summaryRow.push(currencyFormatter(TotalBalance));
                        break;

                    case "TotalAdvance":
                        summaryRow.push(currencyFormatter(TotalAdvance));
                        break;

                    default:
                        summaryRow.push(""); // For non-summary columns, insert an empty cell
                        break;
                }
            }
        }

        return summaryRow;
    };

    const [printMode, setPrintMode] = useState(false);
    const [totalHistory, setTotalHistory] = useState<any>();
    const [count, setCount] = useState(0);
    const [pagination, setPagination] = useState({
        page: 0,
        count: 0,
        limit: 10,
    });
    const { settings } = useContext(UserContext);
    const [clearFilters, setClearFilters] = useState(false);
    const theme = useTheme();

    const [record, setTotalRecord] = useState<any>();
    const ref = useRef(0);

    // Add state for holding balance for each record
    const [balances, setBalances] = useState<{ [key: number]: number }>({});
    const [error, setError] = useState<string | null>(null);

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        ref.current++;
        const newRef = ref.current;
        const { value } = e.target;

        setFilter({ ...filter, seachForShowing: value });

        setTimeout(() => {
            if (newRef === ref.current) {
                setFilter((curr: any) => ({
                    ...curr,
                    ["search"]: value,
                }));

                ref.current = 0;
            }
        }, 1000);
    };

    const handleChange = (ev: ChangeEvent<any>, id: number) => {
        const newValue = parseFloat(ev.target.value) || 0;
        const currentBalance = balances[id] || 0;

        if (newValue > currentBalance) {
            setError("Payment amount cannot exceed the balance.");
        } else {
            setError(null); // Clear error message
            setCollections(() => ({
                ...collections,
                [id]: { ...collections[id], [ev.target.name]: ev.target.value },
            }));
        }
    };

    const handleColumnVisibilityChange = (column: string) => {
        setColumnsVisibility((prev: any) => {
            const newVisibility = {
                ...prev,
                [column]: !prev[column],
            };

            localStorage.setItem(
                "totalCreditDebitRecord",
                JSON.stringify(newVisibility)
            );

            return newVisibility;
        });
    };

    const getTotalRecord = async () => {
        const [data, err] = await PaymentService.getTotalRecord(
            pagination.page + 1,
            pagination.limit,
            {
                client: filter.client ? filter.client : null,
                search: filter.search ? filter.search : null,
                city: filter.city ? filter.city : null,
                ClientType: filter.clientType ? filter.clientType : null,
                startDate: filter.startDate,
                endDate: filter.endDate,
            }
        );

        if (data.rows.length) {
            console.log(data.count);

            setTotalRecord(data.rows);
            setPagination({
                ...pagination,
                count: data?.count ?? 0,
            });
        } else {
            setTotalHistory([]);
            setTotalRecord([]);
        }
    };

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({
            search: "",
            seachForShowing: "",
            clientType: "",
            client: "",
            bank: "",
            apply: 0,
            startDate: "",
            endDate: "",
            status: "",
            city: "",
        });

        setCount(count + 1);
    };

    useEffect(() => {
        getTotalRecord();
    }, [filter.apply, filter.search, count, pagination.page, pagination.limit]);

    useEffect(() => {
        const savedColumns = localStorage.getItem("totalCreditDebitRecord");

        if (savedColumns) {
            setColumnsVisibility(JSON.parse(savedColumns));
        } else {
            setColumnsVisibility({
                SrNo: true,
                Client: true,
                Phone: true,
                City: true,
                TotalDebit: true,
                TotalRefund: true,
                TotalCredit: true,
                TotalAdjustment: true,
                TotalAdvance: true,
                TotalBalance: true,
                Pay: true,
                DetailLeasure: true,
            });
        }
    }, []);

    useEffect(() => {
        // Initialize balances state from records
        if (record) {
            const initialBalances = record.reduce(
                (acc: any, item: any, idx: number) => {
                    acc[idx] = item.TotalBalance; // Assuming TotalBalance is the field for balance
                    return acc;
                },
                {}
            );
            setBalances(initialBalances);
        }
    }, [record]);

    useEffect(() => {
        // Calculate the sum of all payments
        const sum =
            Object.values(collections)?.reduce((acc: any, item: any) => {
                const payment = parseFloat(item.name) || 0;
                return acc + payment;
            }, 0) || 0;

        setTotalPayments(sum);
    }, [Object.values(collections)]);

    return (
        <Grid container spacing={1} style={{ padding: "2rem" }}>
            <Grid container spacing={1} style={{ padding: "2rem" }}>
                <Grid item xs={4}>
                    <Autocomplete
                        api="/city/get-All"
                        setOutput={(ci) =>
                            setFilter({
                                ...filter,
                                city: ci?.id || "",
                            })
                        }
                        label="City"
                        labelKey="name"
                        textFieldProps={{ variant: "outlined", size: "small" }}
                        clear={clearFilters}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Autocomplete
                        labelKey="name"
                        label="Client Type"
                        api="/clientType/get-All"
                        setOutput={(r) =>
                            setFilter({
                                ...filter,
                                clientType: r?.id || null,
                            })
                        }
                        clear={clearFilters}
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                        }}
                    />
                </Grid>

                <Grid item xs={4}>
                    <Autocomplete
                        label="Client"
                        api="/employee/all-employees"
                        labelKey="name"
                        setOutput={(opt) => {
                            setFilter({
                                ...filter,
                                client: opt?.id ?? "",
                            });
                        }}
                        textFieldProps={{ variant: "outlined", size: "small" }}
                        apiParams={{
                            clientType: filter.clientType
                                ? filter.clientType
                                : null,
                            city: filter.city ? filter.city : null,
                        }}
                        clear={clearFilters}
                    />
                </Grid>

                <Grid item xs={4}>
                    <TextField
                        type="date"
                        size="small"
                        fullWidth
                        label="From Date"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                            setFilter({
                                ...filter,
                                startDate: e.target.value,
                            })
                        }
                    />
                </Grid>

                <Grid item xs={4}>
                    <TextField
                        type="date"
                        size="small"
                        fullWidth
                        label="To Date"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                            setFilter({
                                ...filter,
                                endDate: e.target.value,
                            })
                        }
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={4}>
                    <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        onClick={() =>
                            setFilter({
                                ...filter,
                                apply: filter.apply + 1,
                            })
                        }
                        size="small"
                        style={{ height: "80%" }}
                    >
                        Apply filter
                    </Button>
                </Grid>

                <Grid item xs={4}>
                    <Tooltip title="Clear Filters">
                        <IconButton onClick={handleFilterClear} color="primary">
                            <ClearAllIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>

            <Grid
                item
                xs={12}
                style={{
                    borderBottom: `1px solid ${theme.palette.divider}`,
                }}
            ></Grid>

            <Grid
                container
                style={{ padding: "2rem", justifyContent: "space-between" }}
            >
                <Grid
                    item
                    xs={4}
                    style={{ display: "flex", alignItems: "center" }}
                >
                    {Object.keys(filter).includes("search") && (
                        <Grid item style={{ flex: "1" }}>
                            <TextField
                                fullWidth
                                placeholder="Search"
                                variant="outlined"
                                value={filter.seachForShowing}
                                onChange={handleSearch}
                                label="Search"
                                size="small"
                            />
                        </Grid>
                    )}

                    <Tooltip title="Select Columns">
                        <IconButton
                            ref={showColumnsBtn}
                            onClick={() => setShowColumns(true)}
                        >
                            {showColumns ? (
                                <ViewColumn
                                    htmlColor={theme.palette.primary.main}
                                />
                            ) : (
                                <ViewColumnOutlined />
                            )}
                        </IconButton>
                    </Tooltip>

                    <Menu
                        open={showColumns}
                        anchorEl={showColumnsBtn.current}
                        onClose={() => setShowColumns(false)}
                        elevation={4}
                        variant="menu"
                    >
                        {Object.keys(columnsVisibility).map((col) => (
                            <MenuItem key={col} style={{ paddingLeft: 0 }}>
                                <Checkbox
                                    size="small"
                                    checked={columnsVisibility[col]} // Checked if the column is visible
                                    onChange={() =>
                                        handleColumnVisibilityChange(col)
                                    }
                                    color="primary"
                                />

                                <Typography>{col}</Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                </Grid>
                <Grid item xs={4}>
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <PrintTable
                            componentRef={printRef}
                            setPrintMode={setPrintMode}
                        />

                        <TablePagination
                            component="div"
                            onPageChange={(ev, page) =>
                                setPagination({ ...pagination, page: page })
                            }
                            onRowsPerPageChange={(ev) =>
                                setPagination({
                                    ...pagination,
                                    limit: parseInt(ev.target.value),
                                    page: 0,
                                })
                            }
                            count={pagination.count}
                            page={pagination.page}
                            rowsPerPage={pagination.limit}
                            rowsPerPageOptions={[
                                10, 50, 100, 150, 200, 250, 300, 350, 400, 450,
                                500,
                            ]}
                        />
                    </Box>
                </Grid>
            </Grid>

            {record && record.length > 0 ? (
                <Grid item xs={12} ref={printRef}>
                    {printMode && (
                        <ReportHeader
                            orgInfo={(() => {
                                let reportsHeader = settings["reports-header"];
                                try {
                                    reportsHeader = JSON.parse(reportsHeader);
                                } catch (e) {
                                    reportsHeader = null;
                                }

                                return (
                                    reportsHeader || {
                                        name: {
                                            value: "Account Book",
                                            selected: true,
                                        },
                                        email: {
                                            value: "",
                                            selected: false,
                                        },
                                        contactNo: {
                                            value: "",
                                            selected: false,
                                        },
                                        address: {
                                            value: "",
                                            selected: false,
                                        },
                                        logoPos: {
                                            value: "left",
                                            selected: false,
                                        },
                                    }
                                );
                            })()}
                        />
                    )}
                    <TableContainer>
                        <Table size="medium">
                            <TableHead>
                                <TableRow style={{ whiteSpace: "nowrap" }}>
                                    {columnsVisibility.SrNo && (
                                        <TableCell>SR No</TableCell>
                                    )}
                                    {columnsVisibility.Client && (
                                        <TableCell>Client Name</TableCell>
                                    )}

                                    {columnsVisibility.Phone && (
                                        <TableCell>Phone</TableCell>
                                    )}

                                    {columnsVisibility.City && (
                                        <TableCell>City</TableCell>
                                    )}
                                    {columnsVisibility.TotalDebit && (
                                        <TableCell>Total Debit</TableCell>
                                    )}

                                    {columnsVisibility.TotalRefund && (
                                        <TableCell>Total Refund</TableCell>
                                    )}
                                    {columnsVisibility.TotalCredit && (
                                        <TableCell>Total Credit</TableCell>
                                    )}
                                    {columnsVisibility.TotalAdjustment && (
                                        <TableCell>Total Adjustment</TableCell>
                                    )}

                                    {columnsVisibility.TotalAdvance && (
                                        <TableCell>Total Advance</TableCell>
                                    )}

                                    {columnsVisibility.TotalBalance && (
                                        <TableCell>Total Balance</TableCell>
                                    )}

                                    {columnsVisibility.Pay && (
                                        <TableCell>Pay</TableCell>
                                    )}

                                    {columnsVisibility.DetailLeasure && (
                                        <TableCell size="small">
                                            Detail Leasure
                                        </TableCell>
                                    )}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {record?.map((item: any, idx: number) => (
                                    <TableRow key={idx}>
                                        {columnsVisibility.SrNo && (
                                            <TableCell>{idx + 1}</TableCell>
                                        )}

                                        {columnsVisibility.Client && (
                                            <TableCell>{item.client}</TableCell>
                                        )}

                                        {columnsVisibility.Phone && (
                                            <TableCell>
                                                {item.Phone ? (
                                                    item.Phone
                                                ) : (
                                                    <Typography
                                                        style={{
                                                            color: theme.palette
                                                                .divider,
                                                        }}
                                                    >
                                                        N/A
                                                    </Typography>
                                                )}
                                            </TableCell>
                                        )}

                                        {columnsVisibility.City && (
                                            <TableCell>
                                                {item?.City ? (
                                                    item?.City
                                                ) : (
                                                    <Typography
                                                        style={{
                                                            color: theme.palette
                                                                .divider,
                                                        }}
                                                    >
                                                        N/A
                                                    </Typography>
                                                )}
                                            </TableCell>
                                        )}

                                        {columnsVisibility.TotalDebit && (
                                            <TableCell>
                                                {currencyFormatter(
                                                    item.TotalDebit
                                                )}
                                            </TableCell>
                                        )}

                                        {columnsVisibility.TotalRefund && (
                                            <TableCell>
                                                {currencyFormatter(
                                                    item.TotalRefund
                                                )}
                                            </TableCell>
                                        )}

                                        {columnsVisibility.TotalCredit && (
                                            <TableCell>
                                                {currencyFormatter(
                                                    item.TotalCredit
                                                )}
                                            </TableCell>
                                        )}

                                        {columnsVisibility.TotalAdjustment && (
                                            <TableCell>
                                                {currencyFormatter(
                                                    item.TotalAdjustment
                                                )}
                                            </TableCell>
                                        )}

                                        {columnsVisibility.TotalAdvance && (
                                            <TableCell>
                                                {currencyFormatter(
                                                    item.TotalAdvance
                                                )}
                                            </TableCell>
                                        )}

                                        {columnsVisibility.TotalBalance && (
                                            <TableCell>
                                                {currencyFormatter(
                                                    item.TotalBalance
                                                )}
                                            </TableCell>
                                        )}

                                        {columnsVisibility.Pay && (
                                            <TableCell>
                                                <TextField
                                                    style={{
                                                        minWidth: "220px",
                                                    }}
                                                    multiline
                                                    size="small"
                                                    name="name"
                                                    label="Pay"
                                                    value={
                                                        collections[idx]?.name
                                                    }
                                                    onChange={(ev) =>
                                                        handleChange(ev, idx)
                                                    }
                                                    variant="outlined"
                                                />
                                            </TableCell>
                                        )}

                                        {columnsVisibility.DetailLeasure && (
                                            <TableCell
                                                size="small"
                                                style={{
                                                    minWidth: "220px",
                                                }}
                                            >
                                                {" "}
                                                <Link
                                                    to={`/user/payment?id=${item.id}`}
                                                    style={{
                                                        textDecoration: "none",
                                                        color: "white",
                                                        fontSize: "1px",
                                                    }}
                                                >
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        size="small"
                                                    >
                                                        View leasure
                                                    </Button>
                                                </Link>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                ))}

                                <TableRow>
                                    {createSummary(columnsVisibility).map(
                                        (summary, index) => (
                                            <TableCell
                                                key={index}
                                                style={{
                                                    color: theme.palette.primary
                                                        .main,
                                                }}
                                                size="small"
                                            >
                                                {summary}
                                            </TableCell>
                                        )
                                    )}
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Grid
                        item
                        xs={12}
                        style={{
                            paddingTop: "1rem",
                            borderTop: `1px solid ${theme.palette.divider}`,
                        }}
                    >
                        <Typography variant="h6" align="right">
                            Total Payments: {currencyFormatter(totalPayments)}
                        </Typography>
                    </Grid>
                </Grid>
            ) : (
                <Alert severity="info" style={{ width: "100%" }}>
                    No payment record found
                </Alert>
            )}

            {/* Show error message if present */}
            {error && (
                <Grid item xs={12}>
                    <Alert severity="error" style={{ width: "100%" }}>
                        {error}
                    </Alert>
                </Grid>
            )}
        </Grid>
    );
};

export default PaymentRecord;
