import {
    Box,
    Button,
    Chip,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from "@material-ui/core";
import { Check, InfoOutlined } from "@material-ui/icons";
import { Close } from "@mui/icons-material";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { DescriptionTagsService } from "../../../../services/DescriptionTagsService";
import { PaymentService } from "../../../../services/PaymentService";
import { Autocomplete } from "../../../Autocomplete/Autocomplete";
import Filteration from "../../../Filteration/Filteration";
import { RUDTable } from "../../../Table/RUDTable";
import { currencyFormatter, dateFormatter } from "../../../constants";
import PaymentHeadView from "../../PaymentHeadView";
import PaymentImagePreview from "../../PaymentImagePreview";
import DescriptionTagsView from "../DescriptionTagsView";
import UpdateReceipt from "../UpdateReceipt";

const CreditDebitHistory = () => {
    const [filter, setFilter] = useState({
        id: "",
        search: "",
        clientType: "",
        client: "",
        bank: "",
        apply: 0,
        startDate: "",
        endDate: "",
        status: "",
    });

    const theme = useTheme();

    const [creditDebitData, setCreditDebitData] = useState<any>();
    const [count, setCount] = useState(0);
    const [openDescriptionTag, setOpenDescriptionTAg] = useState<any>("");
    const [descriptionTags, setDescriptionTags] = useState<any[]>([]);
    const [descData, setRow] = useState<any>();
    const [id, setId] = useState("");

    const [clearFilters, setClearFilters] = useState(false);
    const createSummary = (cols: any) => {
        const summaryRow = ["Total"];
        let advance = 0;
        let credit = 0;
        let _surcharge = 0;
        let remainingPayment = 0;
        let paidAmount = 0;
        let debit = 0;

        if (creditDebitData) {
            for (const row of creditDebitData) {
                credit += row["credit"];
                debit += row["debit"];
                advance = row["balance"];

                console.log(
                    row["credit"],
                    row["balance"],
                    row["debit"],
                    advance
                );
            }
        }

        for (const col of cols) {
            switch (col) {
                case "credit":
                    summaryRow.push(currencyFormatter(credit));
                    break;

                case "debit":
                    summaryRow.push(currencyFormatter(debit));
                    break;

                case "balance":
                    summaryRow.push(currencyFormatter(advance));
                    break;
                default:
                    summaryRow.push("");
            }
        }

        return summaryRow;
    };

    const history = useHistory();
    const location = useLocation();

    function handlePaymentDetails(row: any) {
        console.log(row);

        history.push(
            `${location.pathname}/details/${row.IdView}/${filter.client}`
        );
    }

    const handleSubmit = async (ev: any) => {
        ev.preventDefault();

        const [data, err] = await PaymentService.updatePayment(
            id as any,
            descriptionTags as any
        );

        if (data) {
            setCount(count + 1);
            setOpenDescriptionTAg(false);
        }
    };

    async function getDescriptionTags() {
        const [data, err] = await DescriptionTagsService.getDescriptionTags(
            1,
            100
        );

        if (data.rows) {
            setRow(data.rows);
        }
    }

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({
            id: "",
            search: "",
            clientType: "",
            client: "",
            bank: "",
            apply: 0,
            startDate: "",
            endDate: "",
            status: "",
        });

        setCount(count + 1);
    };

    const handleChange = (event: any) => {
        setDescriptionTags(event.target.value);
    };

    const handleDelete = (chipToDelete: any) => {
        setDescriptionTags((chips) =>
            chips.filter((chip) => chip !== chipToDelete)
        );
    };

    useEffect(() => {
        getDescriptionTags();
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const id = params.get("id");

        if (Boolean(id)) {
            setFilter(
                (f) =>
                    ({
                        ...f,
                        client: id,
                        apply: filter.apply + 1,
                    } as any)
            );
        }
    }, [location]);

    return (
        <div>
            <Grid container spacing={1} style={{ padding: "1rem" }}>
                <Grid item xs={4}>
                    <Autocomplete
                        labelKey="name"
                        label="Client Type"
                        api="/clientType/get-All"
                        setOutput={(r) =>
                            setFilter({
                                ...filter,
                                clientType: r?.id || null,
                            })
                        }
                        clear={clearFilters}
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Autocomplete
                        label="Client"
                        api="/employee/all-employees"
                        labelKey="name"
                        setOutput={(opt) => {
                            setFilter({
                                ...filter,
                                client: opt?.baseUser?.id ?? "",
                            });
                        }}
                        textFieldProps={{ variant: "outlined", size: "small" }}
                        apiParams={{
                            clientType: filter.clientType,
                        }}
                        clear={clearFilters}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Autocomplete
                        api="/payment/method/get-All"
                        setOutput={(bank: any) => {
                            if (!bank) return;
                            setFilter({ ...filter, bank: bank.id });
                        }}
                        labelKey="name"
                        label="Bank"
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                            // required: true,
                        }}
                        clear={clearFilters}
                    />
                </Grid>

                <Grid item xs={4}>
                    <TextField
                        type="date"
                        size="small"
                        fullWidth
                        label="From Date"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                            setFilter({
                                ...filter,
                                startDate: e.target.value,
                            })
                        }
                    />
                </Grid>

                <Grid item xs={4}>
                    <TextField
                        type="date"
                        size="small"
                        fullWidth
                        label="To Date"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                            setFilter({
                                ...filter,
                                endDate: e.target.value,
                            })
                        }
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={4}>
                    <Autocomplete
                        api="/payment/get-status"
                        setOutput={(des: any) => {
                            if (!des) return;
                            setFilter({
                                ...filter,
                                status: des.status,
                            });
                        }}
                        labelKey="label"
                        label="Status"
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                            // required: true,
                        }}
                    />
                </Grid>

                <Grid item xs={4}>
                    <Button
                        color="primary"
                        fullWidth
                        variant="contained"
                        onClick={() =>
                            setFilter({ ...filter, apply: filter.apply + 1 })
                        }
                        style={{ height: "80%" }}
                    >
                        {" "}
                        Apply Filter
                    </Button>
                </Grid>

                <Grid item xs={3}>
                    <Tooltip title="Clear Filters">
                        <IconButton onClick={handleFilterClear} color="primary">
                            <ClearAllIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            <span
                style={{
                    borderBottom: `1px solid  ${theme.palette.divider}`,
                    display: "block",
                }}
            ></span>

            <RUDTable
                getter={(page, limit) =>
                    PaymentService.getPayments(page, limit, {
                        search: filter.search ? filter.search : null,
                        client: filter.client ? filter.client : null,
                        clientType: filter.clientType
                            ? filter.clientType
                            : null,
                        // city: filter.client ? filter.client : null,
                        bank: filter.bank ? filter.bank : null,
                        startDate: filter.startDate ? filter.startDate : null,
                        endDate: filter.endDate ? filter.endDate : null,
                        status: filter.status ? filter.status : null,

                        // classroom: filter.classroom ? filter.classroom : null,
                    })
                }
                readables={{
                    id: "Transaction Number",
                    name: "Payment Name",
                    client: "Client Name",
                    createdAt: "Creation Date",
                    credit: "Credit",
                    debit: "Debit",
                    balance: "Balance",
                    bank: "Bank Name",
                    status: "Status",
                    description: "Description",
                    descriptionTags: "Description Tags",
                    paymentHeads: "Payment Heads",
                    showImage: "Show Image",
                    details: "Details",
                    ImageUpdate: "Image Update",
                }}
                rowsPreprocessor={(r) => ({
                    ...r,
                    deletionId: r.id,
                    client: r?.client?.employee?.name,
                    clientId: r.client.id,
                    IdView: r.id,
                    bank: r?.bank?.name ? (
                        <Chip label={r.bank.name} size="small" />
                    ) : (
                        <Typography style={{ color: theme.palette.divider }}>
                            N/A
                        </Typography>
                    ),

                    description: r.description ? (
                        r.description
                    ) : (
                        <Typography style={{ color: theme.palette.divider }}>
                            N/A
                        </Typography>
                    ),

                    createdAt: dateFormatter.format(new Date(r.createdAt)),

                    name: r?.name || (
                        <Typography style={{ color: theme.palette.divider }}>
                            N/A
                        </Typography>
                    ),
                    status:
                        r.status == "payment" &&
                        r.credit == 0 &&
                        r.debit >= 0 ? (
                            <Chip
                                size="small"
                                style={{
                                    background:
                                        "linear-gradient(45deg, #7F0900, #FF1200)",
                                }}
                                label="Debit"
                            />
                        ) : r.status == "payment" &&
                          r.credit >= 0 &&
                          r.debit == 0 ? (
                            <Chip
                                size="small"
                                style={{
                                    background:
                                        "linear-gradient(45deg, #45BF55, #00261C)",
                                }}
                                label="Credit"
                            />
                        ) : r.status == "adjustment" &&
                          r.credit >= 0 &&
                          r.debit == 0 ? (
                            <Chip
                                size="small"
                                style={{
                                    background:
                                        "linear-gradient(0deg,#F2AA00,#FF7C00)",
                                }}
                                label="Adjustment"
                            />
                        ) : r.status == "advance" &&
                          r.credit >= 0 &&
                          r.debit == 0 ? (
                            <Chip
                                size="small"
                                style={{
                                    background:
                                        "linear-gradient(0deg, #023373, #0F9BF2)",
                                }}
                                label="Advance"
                            />
                        ) : (
                            r.status == "refund" &&
                            r.credit == 0 &&
                            r.debit >= 0 && (
                                <Chip
                                    size="small"
                                    style={{
                                        background:
                                            "linear-gradient(0deg, #9C3E00, #502000)",
                                    }}
                                    label="Refund"
                                />
                            )
                        ),
                    id: "TRA-" + r.id,
                })}
                ops={{
                    read: "READ PAYMENT",
                    edit: "EDIT PAYMENT",
                    delete: "DELETE PAYMENT",
                }}
                updatingAgents={[filter.search, filter.apply, count]}
                editables={[]}
                customCols={[
                    {
                        header: "descriptionTags",
                        content: (row: any) => {
                            const desc =
                                row.descTagsComponent.length > 0 ? (
                                    <>
                                        <div
                                            onClick={(ev) => {
                                                setId(row.IdView);
                                                ev.stopPropagation();
                                                setOpenDescriptionTAg(
                                                    row.IdView
                                                );
                                                setDescriptionTags(
                                                    row?.descTagsComponent?.map(
                                                        (item: any) =>
                                                            item?.descTags?.id
                                                    )
                                                );
                                            }}
                                        >
                                            {openDescriptionTag !=
                                                row.IdView && (
                                                <DescriptionTagsView
                                                    row={row}
                                                />
                                            )}
                                        </div>

                                        {openDescriptionTag == row.IdView && (
                                            <div
                                                style={{
                                                    minWidth: "280px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "1px",
                                                }}
                                            >
                                                <FormControl
                                                    fullWidth
                                                    variant="outlined"
                                                >
                                                    <InputLabel id="payment-type-label">
                                                        Payment Type
                                                    </InputLabel>
                                                    <Select
                                                        labelId="payment-type-label"
                                                        multiple // Enable multiple selection
                                                        value={descriptionTags}
                                                        onChange={handleChange}
                                                        label="Payment Type"
                                                        defaultValue={row?.descTagsComponent?.map(
                                                            (item: any) =>
                                                                item?.descTags
                                                                    ?.id
                                                        )}
                                                        renderValue={(
                                                            selected: any
                                                        ) => (
                                                            <Box
                                                                sx={{
                                                                    display:
                                                                        "flex",
                                                                    flexWrap:
                                                                        "wrap",
                                                                }}
                                                            >
                                                                {selected.map(
                                                                    (
                                                                        value: any
                                                                    ) => (
                                                                        <Chip
                                                                            key={
                                                                                value
                                                                            }
                                                                            label={
                                                                                descData.find(
                                                                                    (
                                                                                        item: any
                                                                                    ) =>
                                                                                        item.id ===
                                                                                        value
                                                                                )
                                                                                    ?.name
                                                                            }
                                                                            onDelete={() =>
                                                                                handleDelete(
                                                                                    value
                                                                                )
                                                                            }
                                                                            onMouseDown={(
                                                                                event
                                                                            ) =>
                                                                                event.stopPropagation()
                                                                            }
                                                                        />
                                                                    )
                                                                )}
                                                            </Box>
                                                        )}
                                                    >
                                                        {descData.map(
                                                            (item: any) => (
                                                                <MenuItem
                                                                    value={
                                                                        item.id
                                                                    }
                                                                >
                                                                    {item.name}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>

                                                <IconButton
                                                    size="small"
                                                    type="submit"
                                                    onClick={handleSubmit}
                                                >
                                                    <Check fontSize="small" />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() =>
                                                        setOpenDescriptionTAg(
                                                            false
                                                        )
                                                    }
                                                    size="small"
                                                >
                                                    <Close fontSize="small" />
                                                </IconButton>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <Typography
                                        style={{ color: theme.palette.divider }}
                                    >
                                        N/A
                                    </Typography>
                                );

                            return desc;
                        },
                    },

                    {
                        header: "paymentHeads",
                        content: (row) => {
                            return <PaymentHeadView row={row} />;
                        },
                    },

                    {
                        header: "showImage",
                        content: (row) => (
                            <PaymentImagePreview id={row.IdView} />
                        ),
                    },

                    {
                        header: "ImageUpdate",
                        content: (row) => <UpdateReceipt id={row.IdView} />,
                    },

                    {
                        header: "details",
                        content: (row) => (
                            <Tooltip title="Payment Details">
                                <IconButton
                                    onClick={() => handlePaymentDetails(row)}
                                    size="small"
                                >
                                    <InfoOutlined fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        ),
                    },
                ]}
                getRows={(rows) => {
                    return setCreditDebitData(rows);
                }}
                summaryRow={createSummary}
                filters={
                    <Filteration
                        handleClear={handleFilterClear}
                        filter={filter}
                        setFilter={setFilter}
                    />
                }
                storageKey="CreditDebit"
            />
        </div>
    );
};

export default CreditDebitHistory;
