import { baseAPI, notConnectedMessage } from "../components/constants";
import { POST } from "./BaseService";

const { default: axios } = require("axios");

export const logout = async () => {
    const endpoint = `${baseAPI}/user/logout`;
    try {
        const response = await axios.post(
            endpoint,
            {},
            { withCredentials: true }
        );
        return [response.data, null];
    } catch (error) {
        console.log(error.response);
        return [null, error.response?.data?.message || notConnectedMessage];
    }
};

export const editUser = async (id, newUser) => {
    try {
        const api = `${baseAPI}/user`;
        const response = await axios.patch(api, newUser, {
            withCredentials: true,
            params: { id },
        });
        return [response.data, null];
    } catch (err) {
        console.log(err.response);
        return [null, err.response?.data?.message || notConnectedMessage];
    }
};

export const deleteUser = async (baseUserIds, type) => {
    try {
        const response = await axios.delete(
            `${baseAPI}/baseuser/delete/${type}`,
            {
                data: { baseUserIds },
                withCredentials: true,
            }
        );
        return [response.data, null];
    } catch (err) {
        console.log(err);
        return [null, err.response?.data?.message || notConnectedMessage];
    }
};

export const allUsers = async (page = 0, limit = 10, filter = {}) => {
    try {
        const response = await axios.get(`${baseAPI}/user/all-users`, {
            withCredentials: true,
            params: {
                page: filter.search ? undefined : page,
                limit: filter.search ? undefined : limit,
                ...filter,
            },
        });
        return [response.data, null];
    } catch (err) {
        console.log(err);
        return [null, err.response?.data?.message || notConnectedMessage];
    }
};

export const allOrganizationAdminUsers = async (
    page = 0,
    limit = 10,
    filter = {}
) => {
    try {
        const response = await axios.get(
            `${baseAPI}/user/all-organizationAdmin`,
            {
                withCredentials: true,
                params: {
                    page: filter.search ? undefined : page,
                    limit: filter.search ? undefined : limit,
                    ...filter,
                },
            }
        );
        return [response.data, null];
    } catch (err) {
        console.log(err);
        return [null, err.response?.data?.message || notConnectedMessage];
    }
};

export const changePassword = async ({ existingPassword, newPassword }) => {
    try {
        const response = await axios.patch(
            `${baseAPI}/baseuser/password`,
            { existingPassword, newPassword },
            {
                withCredentials: true,
            }
        );
        return [response.data, null];
    } catch (err) {
        return [null, err.response?.data?.message || notConnectedMessage];
    }
};

export const setUserAuthenticationNumber = async ({ number }) => {
    try {
        const response = await axios.patch(
            `${baseAPI}/user/mutiple-authentication-number`,
            { number },
            {
                withCredentials: true,
            }
        );
        return [response.data, null];
    } catch (err) {
        return [null, err.response?.data?.message || notConnectedMessage];
    }
};

export const getUsers = async (baseUserIds) => {
    try {
        const response = await axios.get(baseAPI + "/baseuser/info", {
            withCredentials: true,
            params: { ids: baseUserIds },
        });
        return [response.data, null];
    } catch (err) {
        console.log(err);
        return [null, err.response?.data?.message ?? notConnectedMessage];
    }
};

export const checkExistence = async (credentials) =>
    POST("/baseuser/check-user", credentials);

export const verifyUsers = async (payload) => POST("/user/verify", payload);
