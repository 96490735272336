import {
    Chip,
    Divider,
    IconButton,
    Tooltip,
    Typography,
    makeStyles,
    useTheme,
} from "@material-ui/core";
import { useState } from "react";
import { ArchiveService } from "../../services/ArchiveService";
import {
    allClassesAdmin,
    editClassroom,
} from "../../services/classroom.service";
import Authorize from "../Authorize";
import { Autocomplete } from "../Autocomplete/Autocomplete";
import Filteration from "../Filteration/Filteration";
import { RUDTable } from "../Table/RUDTable";
import AddClassroom from "./add-classroom";

const useStyles = makeStyles((theme) => ({
    root: {},
    cell: {
        backgroundColor: theme.palette.secondary.light,
    },
    addSection: {
        // marginTop: "2vh",
        marginBottom: "2vh",
    },
    addClass: {
        margin: theme.spacing(2),
    },
    tableRow: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.grey[200],
        },
    },
}));

const CopyContentIcon = () => (
    <svg
        className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc"
        focusable="false"
        aria-hidden="true"
        viewBox="0 0 24 24"
        data-testid="ContentCopyIcon"
    >
        <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"></path>
    </svg>
);

export default function Classrooms() {
    const theme = useTheme();

    const [classesAdded, setClassesAdded] = useState(0);
    const [tooltipText, setTooltipText] = useState("Copy to clipboard");
    const [filter, setFilter] = useState({
        search: "",
        apply: 0,
        public: null,
    });

    console.log(filter.public);

    // const handleClick = (e, filter) => {
    //     setFilter({ ...filter, [e.target.name]: e.target.value });
    // };
    const [clearFilters, setClearFilters] = useState(false);

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({
            apply: 0,
            search: "",
            public: null,
        });
    };

    const handleCopy = (text) => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text);

            setTooltipText("Copied to clipboard");

            setTimeout(() => {
                setTooltipText("Copy to clipboard");
            }, 1000);
        }
    };

    return (
        <>
            <Authorize
                opName="CREATE CLASSROOM"
                // fallback={
                //     <AuthorizeFallback message="You're not authorized to access this section" />
                // }
            >
                <AddClassroom setClassesAdded={setClassesAdded} />
            </Authorize>

            <Divider />

            <RUDTable
                getter={(page, limit) =>
                    allClassesAdmin(page, limit, {
                        search: filter.search ? filter.search : null,
                        public:
                            filter.public === 1
                                ? 1
                                : filter.public === 0
                                ? 0
                                : null,
                    })
                }
                editor={editClassroom}
                updatingAgents={[classesAdded, filter.search, filter.apply]}
                readables={{
                    id: "ID",
                    class: "Name",
                    name: "Creator",
                    // public: "Visibility",
                    status: "Status",
                    Token: "Token",
                }}
                rowsPreprocessor={(r) => ({
                    ...r,
                    name: r.user.name,
                    public: `${r.public ? "Public" : "Private"}`,
                    deletionId: r.id,
                    status: (
                        <Chip
                            label={r.status}
                            style={{
                                textTransform: "capitalize",
                                backgroundColor:
                                    r.status === "active"
                                        ? theme.palette.success.dark
                                        : theme.palette.error.dark,
                            }}
                        />
                    ),

                    defaultStatus:
                        r.status == "active"
                            ? { id: "active", name: "Active" }
                            : { id: "pending", name: "Pending" },

                    // defaultVisibility: r.public
                    //     ? { id: true, name: "Public" }
                    //     : { id: false, name: "Private" },
                })}
                customCols={[
                    {
                        header: "Token",
                        content: (row) => (
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "25ch",
                                }}
                            >
                                <Typography variant="body2">
                                    {row.token}
                                </Typography>
                                <Tooltip title={tooltipText} placement="top">
                                    <IconButton
                                        size="small"
                                        color="secondary"
                                        onClick={() => handleCopy(row.token)}
                                    >
                                        <CopyContentIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        ),
                    },
                ]}
                deletor={(ids) => ArchiveService.archive(ids[0], "classroom")}
                ops={{
                    read: "READ CLASSROOMS",
                    edit: "EDIT CLASSROOM",
                    delete: "DELETE CLASSROOMS",
                }}
                editables={[
                    {
                        name: "class",
                        type: "textfield",
                        label: "Classroom",
                    },
                    {
                        name: "token",
                        type: "textfield",
                        label: "Token",
                    },

                    {
                        name: "status",
                        type: "autocomplete",
                        label: "Visibility",
                        autocomplete: {
                            labelKey: "name",
                            label: "Status",
                            defaultOptions: [
                                { id: "active", name: "Active" },
                                { id: "pending", name: "Pending" },
                            ],
                            defaultValue: "defaultStatus",
                        },
                    },
                ]}
                filters={
                    <Filteration
                        handleClear={handleFilterClear}
                        filter={filter}
                        setFilter={setFilter}
                        fields={[
                            <Autocomplete
                                labelKey="name"
                                label="Visibility"
                                defaultOptions={[
                                    { id: 0, name: "Private" },
                                    { id: 1, name: "Public" },
                                ]}
                                setOutput={(r) =>
                                    setFilter({
                                        ...filter,
                                        public: r?.id,
                                    })
                                }
                                clear={clearFilters}
                                textFieldProps={{ variant: "outlined" }}
                            />,
                        ]}
                    />
                }
            />
        </>
    );
}
