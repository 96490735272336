import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Checkbox,
    CircularProgress,
    Grid,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import { RolesService } from "../../services/RolesService";
import { ParseTags } from "../ParseTags";
import UserContext from "../UserContext";

type NewUser = {
    name: string;
    email: string;
    password: string;
    roleId: number | null;
    token: string;
};

const initialFeedback = {
    hidden: true,
    message: "",
    severity: "error",
    loading: false,
};

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
    },
    form: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        "& > *": {
            minWidth: "30%",
            margin: theme.spacing(1),
        },
    },
}));

export const CreateUser = ({ activeRow, setActiveRow, setUserAdded }: any) => {
    const classes = useStyles();
    const [roles, setRoles] = useState<any[]>([]);
    const [rolesCount, setRolesCount] = useState(0);
    const [pagination, setPagination] = useState({
        current: 0,
        limit: 10,
    });
    const [user, setUser] = useState<NewUser>({
        name: "",
        email: "",
        password: "",
        roleId: null,
        token: "",
    });
    const [feedback, setFeedback] = useState<any>(initialFeedback);
    const { user: ctxUser } = useContext(UserContext);

    const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
        setUser({ ...user, [ev.target.name]: ev.target.value });
    };

    const handleCheckChange = (
        ev: ChangeEvent<HTMLInputElement>,
        checked: boolean,
        roleId: any
    ) => {
        if (checked) {
            setUser({ ...user, roleId: roleId });
        } else {
            setUser({ ...user, roleId: null });
        }
    };

    const getRoles = async () => {
        const [data, err] = await RolesService.getRoles(
            pagination.current + 1,
            pagination.limit,
            {},
            !Boolean(ctxUser.classroom)
        );
        setRolesCount(data.count);
        setRoles(data.rows);
    };

    const handlePageChange = async (ev: any, page: number) => {
        setPagination({ ...pagination, current: page });
    };

    const handleRowsPerPageChange = async (ev: any) => {
        setPagination({ ...pagination, current: 0, limit: ev.target.value });
    };

    const handleSubmit = async (ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();

        if (user.roleId === null) {
            setFeedback((fb: any) => ({
                ...fb,
                severity: "warning",
                message: "Must select a role",
                hidden: false,
                loading: false,
            }));
            return;
        } else {
            setFeedback(initialFeedback);
        }

        setFeedback({ ...feedback, loading: true });

        console.log(ctxUser);

        const [data, err] = await RolesService.createAdminByRole({
            ...user,
            userStatus: ctxUser.classroom
                ? "normalOrganizationUser"
                : "organizationAdmin",
        });

        if (err) {
            setFeedback({
                hidden: false,
                message: err,
                severity: "error",
                loading: false,
            });
        } else {
            setFeedback({
                hidden: false,
                message: data.message,
                severity: "success",
                loading: false,
            });

            setUserAdded((n: number) => n + 1);
        }
    };

    useEffect(() => {
        getRoles();
    }, []);

    useEffect(() => {
        getRoles();
    }, [pagination]);

    return (
        <Accordion className={classes.root}>
            <AccordionSummary>
                <Typography variant="h5">Create User</Typography>
            </AccordionSummary>

            <AccordionDetails
                style={{ display: "flex", flexDirection: "column" }}
            >
                <Grid
                    container
                    // className={classes.form}
                    component="form"
                    onSubmit={handleSubmit}
                    spacing={1}
                    style={{ paddingLeft: "1rem" }}
                >
                    <Grid item xs={12} style={{ paddingBottom: "1rem" }}>
                        {!feedback.hidden && (
                            <Alert
                                style={{ width: "100%" }}
                                severity={feedback.severity as any}
                            >
                                {feedback.message}
                            </Alert>
                        )}
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            required
                            variant="outlined"
                            label="Email"
                            name="email"
                            type="text"
                            value={user.email}
                            onChange={handleChange}
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            required
                            variant="outlined"
                            label="Password"
                            name="password"
                            type="password"
                            value={user.password}
                            onChange={handleChange}
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            required
                            variant="outlined"
                            label="Name"
                            name="name"
                            type="text"
                            value={user.name}
                            onChange={handleChange}
                        />
                    </Grid>

                    {ctxUser?.classroom && ctxUser?.classroom.id ? null : (
                        <Grid item xs={12} md={3}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Oragnization token (optional)"
                                name="token"
                                type="text"
                                value={user.token}
                                onChange={handleChange}
                            />
                        </Grid>
                    )}

                    {/* <Grid item xs={12}>
                <Typography
                    variant="h4"
                    gutterBottom
                    style={{ alignSelf: "flex-start" }}
                >
                    Roles
                </Typography>
            </Grid> */}

                    <Grid
                        item
                        xs={12}
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Typography variant="h6">Assign a role</Typography>
                        <TablePagination
                            component="div"
                            count={rolesCount}
                            page={pagination.current}
                            onPageChange={handlePageChange}
                            rowsPerPage={pagination.limit}
                            onRowsPerPageChange={handleRowsPerPageChange}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Role</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {roles && roles.length > 0
                                        ? roles.map((role) => (
                                              <TableRow key={role.id}>
                                                  <TableCell>
                                                      <ParseTags
                                                          text={role.name}
                                                          chipProps={{
                                                              color: "primary",
                                                          }}
                                                      />
                                                  </TableCell>
                                                  <TableCell>
                                                      <Checkbox
                                                          size="small"
                                                          color="primary"
                                                          onChange={(
                                                              ev,
                                                              checked
                                                          ) =>
                                                              handleCheckChange(
                                                                  ev,
                                                                  checked,
                                                                  role.id
                                                              )
                                                          }
                                                          checked={Boolean(
                                                              user.roleId ===
                                                                  role.id
                                                          )}
                                                      />
                                                  </TableCell>
                                              </TableRow>
                                          ))
                                        : "No Roles Acess"}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            variant="outlined"
                            color="primary"
                            type="submit"
                            disabled={feedback.loading}
                            endIcon={
                                feedback.loading ? (
                                    <CircularProgress size="1em" />
                                ) : null
                            }
                        >
                            {feedback.loading ? "Creating ..." : "Create User"}
                        </Button>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};
