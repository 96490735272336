import {
    Box,
    Button,
    Grid,
    IconButton,
    Theme,
    Tooltip,
    createStyles,
    makeStyles,
    useTheme,
} from "@material-ui/core";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import { useEffect, useState } from "react";
import { SimpleDashboardService } from "../../services/SimpleDashboardService";
import { Autocomplete } from "../Autocomplete/Autocomplete";
import Donut from "./Donut";
import SimpleDashboardCard from "./SimpleDashboardCard";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: { padding: theme.spacing(2) },
    })
);

const initaiTotalCounts = {
    totalCredit: "0",
    totalDebit: "0",
};

const SimpleDashboard = () => {
    const [totalAmount, setTotalAmount] = useState<any>(0);
    const [singleDayAmount, setSingleDayAmount] = useState<any>(0);
    const [clearFilters, setClearFilters] = useState(false);
    const [count, setCount] = useState(0);
    const classes = useStyles();
    const theme = useTheme();

    const [filter, setFilter] = useState({
        search: "",
        seachForShowing: "",
        clientType: "",
        client: "",
        bank: "",
        apply: 0,
        startDate: "",
        endDate: "",
        status: "",
        city: "",
    });

    const getTotalAmount = async () => {
        const [data, err] = await SimpleDashboardService.getTotalAmount({
            clientType: filter.clientType,
            client: filter.client,
        });

        if (data) {
            setTotalAmount(data);
        }
    };

    const getSingleDayAmounts = async () => {
        const [data, err] = await SimpleDashboardService.getSingleDayAmount({
            clientType: filter.clientType,
            client: filter.client,
        });

        if (data) {
            setSingleDayAmount(data);
        }
    };

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({
            search: "",
            seachForShowing: "",
            clientType: "",
            client: "",
            bank: "",
            apply: 0,
            startDate: "",
            endDate: "",
            status: "",
            city: "",
        });

        setCount(count + 1);
    };

    useEffect(() => {
        getTotalAmount();
        getSingleDayAmounts();
    }, [filter.apply, count]);

    return (
        <Box style={{ padding: "1rem" }}>
            <Grid container spacing={1} style={{ padding: "2rem" }}>
                <Grid item xs={3}>
                    <Autocomplete
                        labelKey="name"
                        label="Client Type"
                        api="/clientType/get-All"
                        setOutput={(r) =>
                            setFilter({
                                ...filter,
                                clientType: r?.id || null,
                            })
                        }
                        clear={clearFilters}
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                        }}
                    />
                </Grid>

                <Grid item xs={3}>
                    <Autocomplete
                        label="Client"
                        api="/employee/all-employees"
                        labelKey="name"
                        setOutput={(opt) => {
                            setFilter({
                                ...filter,
                                client: opt.baseUser?.id ?? "",
                            });
                        }}
                        textFieldProps={{ variant: "outlined", size: "small" }}
                        apiParams={{
                            clientType: filter.clientType
                                ? filter.clientType
                                : null,
                            city: filter.city ? filter.city : null,
                        }}
                        clear={clearFilters}
                    />
                </Grid>

                <Grid item xs={3}>
                    <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        onClick={() =>
                            setFilter({
                                ...filter,
                                apply: filter.apply + 1,
                            })
                        }
                        size="small"
                        style={{ height: "80%" }}
                    >
                        Apply filter
                    </Button>
                </Grid>

                <Grid item xs={3}>
                    <Tooltip title="Clear Filters">
                        <IconButton onClick={handleFilterClear} color="primary">
                            <ClearAllIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>

            <Grid
                item
                xs={12}
                style={{
                    borderBottom: `1px solid ${theme.palette.divider}`,
                }}
            ></Grid>

            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                    <Donut
                        series={[
                            parseInt(totalAmount.totalCredit),
                            parseInt(totalAmount.totalDebit),
                            parseInt(totalAmount.TotalAdvance as any),
                            parseInt(totalAmount.TotalAdjustment as any),
                            parseInt(totalAmount.TotalBalance),
                            parseInt(totalAmount.TotalRefund as any),
                        ]}
                        labels={[
                            "Total Credit",
                            "Total Debit",
                            "Advance Amount",
                            "Adjusted Amount",
                            "Total Balance",
                            "Refund Amount",
                        ]}
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <Donut
                        series={[
                            parseInt(singleDayAmount.TodayCredit),
                            parseInt(singleDayAmount.TodayDebit),
                            parseInt(singleDayAmount.TodayAdvance as any),
                            parseInt(singleDayAmount.TodayAdjustment as any),
                            parseInt(singleDayAmount.TodayBalance),
                            parseInt(singleDayAmount.TodayRefund as any),
                        ]}
                        labels={[
                            "Today Credit",
                            "Today Debit",
                            "Today Advance Amount",
                            "Today Adjustement Amount",
                            "Today Balance",
                            "Today Refund",
                        ]}
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <SimpleDashboardCard
                        label={"Total Credit Amount"}
                        value={parseInt(totalAmount.totalCredit)}
                        // bg={"linear-gradient(45deg, #45BF55, #00261C)"}
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <SimpleDashboardCard
                        label={"Total Adjustment Amount"}
                        value={totalAmount.TotalAdjustment}
                        // bg="linear-gradient(0deg, #023373, #0F9BF2)"
                        // bg={"linear-gradient(0deg,#F2AA00,#FF7C00)"}
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <SimpleDashboardCard
                        label={"Total Advance Amount"}
                        value={totalAmount.TotalAdvance}
                        // bg="linear-gradient(0deg, #023373, #0F9BF2)"
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <SimpleDashboardCard
                        label={"Total RefundAmount"}
                        value={totalAmount.TotalRefund}
                        // bg="linear-gradient(0deg, #023373, #0F9BF2)"
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <SimpleDashboardCard
                        label={"Total Debit Amount"}
                        value={parseInt(totalAmount.totalDebit)}
                        // bg="linear-gradient(45deg, #7F0900, #FF1200)"
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <SimpleDashboardCard
                        label={"Total Balance Amount"}
                        value={parseInt(totalAmount.TotalBalance)}
                        // bg="linear-gradient(45deg, #0D0D0D, #262626)"
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <SimpleDashboardCard
                        label={"Today  Credit Amount"}
                        value={parseInt(singleDayAmount.TodayCredit)}
                        // bg={"linear-gradient(45deg, #45BF55, #00261C)"}
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <SimpleDashboardCard
                        label={"Today Adjustment Amount"}
                        value={singleDayAmount.TodayAdjustment}
                        // bg="linear-gradient(0deg, #023373, #0F9BF2)"
                        // bg={"linear-gradient(0deg,#F2AA00,#FF7C00)"}
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <SimpleDashboardCard
                        label={"Today Advance Amount"}
                        value={singleDayAmount.TodayAdvance}
                        // bg="linear-gradient(0deg, #023373, #0F9BF2)"
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <SimpleDashboardCard
                        label={"Today Refund Amount"}
                        value={singleDayAmount.TodayRefund}
                        // bg="linear-gradient(45deg, #7F0900, #FF1200)"
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <SimpleDashboardCard
                        label={"Today Debit Amount"}
                        value={parseInt(singleDayAmount.TodayDebit)}
                        // bg="linear-gradient(45deg, #7F0900, #FF1200)"
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <SimpleDashboardCard
                        label={"Today Balance Amount"}
                        value={parseInt(singleDayAmount.TodayBalance)}
                        // bg="linear-gradient(45deg, #0D0D0D, #262626)"
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default SimpleDashboard;
