import {
    Box,
    Button,
    Checkbox,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from "@material-ui/core";
import { ViewColumn, ViewColumnOutlined } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { PaymentService } from "../../services/PaymentService";
import { Autocomplete } from "../Autocomplete/Autocomplete";
import { currencyFormatter } from "../constants";
import PrintTable from "../export/PrintTable";
import { ReportHeader } from "../settings/Header/ReportHeader";
import UserContext from "../UserContext";

const TrialBalance = () => {
    const [filter, setFilter] = useState({
        search: "",
        seachForShowing: "",
        clientType: "",
        client: "",
        bank: "",
        apply: 0,
        startDate: "",
        endDate: "",
        status: "",
        city: "",
    });

    const [showColumns, setShowColumns] = useState(false);
    const [_updateSelected, setUpdateSelected] = useState<any>();
    const showColumnsBtn = useRef<HTMLButtonElement | null>(null);

    const [columnsVisibility, setColumnsVisibility] = useState<any>({
        SrNo: true,
        Client: true,
        Phone: true,
        City: true,
        balance: true,
        collection: true,
    });
    const printRef = useRef<HTMLDivElement | null>(null);

    const [printMode, setPrintMode] = useState(false);
    const [paymentToBePaid, setPaymentToBePaid] = useState<any>();
    const [count, setCount] = useState(0);
    const [pagination, setPagination] = useState({
        page: 0,
        count: 0,
        limit: 10,
    });
    const { settings } = useContext(UserContext);
    const [clearFilters, setClearFilters] = useState(false);
    const theme = useTheme();

    const [trialBalance, setTrialBalance] = useState<any>();
    const ref = useRef(0);

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        ref.current++;
        const newRef = ref.current;
        const { value } = e.target;

        setFilter({ ...filter, seachForShowing: value });

        setTimeout(() => {
            if (newRef === ref.current) {
                setFilter((curr: any) => ({
                    ...curr,
                    ["search"]: value,
                }));

                ref.current = 0;
            }
        }, 1000);
    };

    const handleColumnVisibilityChange = (column: string) => {
        setColumnsVisibility((prev: any) => {
            const newVisibility = {
                ...prev,
                [column]: !prev[column],
            };

            localStorage.setItem(
                "trialBalanceRecord",
                JSON.stringify(newVisibility)
            );

            return newVisibility;
        });
    };

    const getTrialBalance = async () => {
        const [data, err] = await PaymentService.getPaymentsToBePaid(
            pagination.page + 1,
            pagination.limit,
            {
                client: filter.client ? filter.client : null,
                search: filter.search ? filter.search : null,
                city: filter.city ? filter.city : null,
                ClientType: filter.clientType ? filter.clientType : null,
            }
        );

        if (data.rows.length) {
            setTrialBalance(data.rows);
            setPagination({
                ...pagination,
                count: data?.count ?? 0,
            });
        } else {
            setPaymentToBePaid([]);
            setTrialBalance([]);
        }
    };

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({
            search: "",
            seachForShowing: "",
            clientType: "",
            client: "",
            bank: "",
            apply: 0,
            startDate: "",
            endDate: "",
            status: "",
            city: "",
        });

        setCount(count + 1);
    };

    useEffect(() => {
        getTrialBalance();
    }, [filter.apply, filter.search, count, pagination.page, pagination.limit]);

    useEffect(() => {
        const savedColumns = localStorage.getItem("trialBalanceRecord");

        if (savedColumns) {
            setColumnsVisibility(JSON.parse(savedColumns));
        } else {
            setColumnsVisibility({
                SrNo: true,
                Client: true,
                Phone: true,
                City: true,
                balance: true,
                collection: true,
            });
        }
    }, []);

    return (
        <Grid container spacing={1} style={{ padding: "2rem" }}>
            <Grid container spacing={1} style={{ padding: "2rem" }}>
                <Grid item xs={4}>
                    <Autocomplete
                        api="/city/get-All"
                        setOutput={(ci) =>
                            setFilter({
                                ...filter,
                                city: ci?.id || "",
                            })
                        }
                        label="City"
                        labelKey="name"
                        textFieldProps={{ variant: "outlined", size: "small" }}
                        clear={clearFilters}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Autocomplete
                        labelKey="name"
                        label="Client Type"
                        api="/clientType/get-All"
                        setOutput={(r) =>
                            setFilter({
                                ...filter,
                                clientType: r?.id || null,
                            })
                        }
                        clear={clearFilters}
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                        }}
                    />
                </Grid>

                <Grid item xs={4}>
                    <Autocomplete
                        label="Client"
                        api="/employee/all-employees"
                        labelKey="name"
                        setOutput={(opt) => {
                            setFilter({
                                ...filter,
                                client: opt?.id ?? "",
                            });
                        }}
                        textFieldProps={{ variant: "outlined", size: "small" }}
                        apiParams={{
                            clientType: filter.clientType
                                ? filter.clientType
                                : null,
                            city: filter.city ? filter.city : null,
                        }}
                        clear={clearFilters}
                    />
                </Grid>

                <Grid item xs={4}>
                    <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        onClick={() =>
                            setFilter({
                                ...filter,
                                apply: filter.apply + 1,
                            })
                        }
                        size="small"
                        style={{ height: "80%" }}
                    >
                        Apply filter
                    </Button>
                </Grid>

                <Grid item xs={4}>
                    <Tooltip title="Clear Filters">
                        <IconButton onClick={handleFilterClear} color="primary">
                            <ClearAllIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>

            <Grid
                item
                xs={12}
                style={{
                    borderBottom: `1px solid ${theme.palette.divider}`,
                }}
            ></Grid>

            <Grid
                container
                style={{ padding: "2rem", justifyContent: "space-between" }}
            >
                <Grid
                    item
                    xs={4}
                    style={{ display: "flex", alignItems: "center" }}
                >
                    {Object.keys(filter).includes("search") && (
                        <Grid item style={{ flex: "1" }}>
                            <TextField
                                fullWidth
                                placeholder="Search"
                                variant="outlined"
                                value={filter.seachForShowing}
                                onChange={handleSearch}
                                label="Search"
                                size="small"
                            />
                        </Grid>
                    )}

                    <Tooltip title="Select Columns">
                        <IconButton
                            ref={showColumnsBtn}
                            onClick={() => setShowColumns(true)}
                        >
                            {showColumns ? (
                                <ViewColumn
                                    htmlColor={theme.palette.primary.main}
                                />
                            ) : (
                                <ViewColumnOutlined />
                            )}
                        </IconButton>
                    </Tooltip>

                    <Menu
                        open={showColumns}
                        anchorEl={showColumnsBtn.current}
                        onClose={() => setShowColumns(false)}
                        elevation={4}
                        variant="menu"
                    >
                        {Object.keys(columnsVisibility).map((col) => (
                            <MenuItem key={col} style={{ paddingLeft: 0 }}>
                                <Checkbox
                                    size="small"
                                    checked={columnsVisibility[col]} // Checked if the column is visible
                                    onChange={() =>
                                        handleColumnVisibilityChange(col)
                                    }
                                    color="primary"
                                />

                                <Typography>{col}</Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                </Grid>
                <Grid item xs={4}>
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <PrintTable
                            componentRef={printRef}
                            setPrintMode={setPrintMode}
                        />

                        <TablePagination
                            component="div"
                            onPageChange={(ev, page) =>
                                setPagination({ ...pagination, page: page })
                            }
                            onRowsPerPageChange={(ev) =>
                                setPagination({
                                    ...pagination,
                                    limit: parseInt(ev.target.value),
                                    page: 0,
                                })
                            }
                            count={pagination.count}
                            page={pagination.page}
                            rowsPerPage={pagination.limit}
                        />
                    </Box>
                </Grid>
            </Grid>

            {trialBalance && trialBalance.length > 0 ? (
                <Grid item xs={12} ref={printRef}>
                    {printMode && (
                        <ReportHeader
                            orgInfo={(() => {
                                let reportsHeader = settings["reports-header"];
                                try {
                                    reportsHeader = JSON.parse(reportsHeader);
                                } catch (e) {
                                    reportsHeader = null;
                                }

                                return (
                                    reportsHeader || {
                                        name: {
                                            value: "Account Book",
                                            selected: true,
                                        },
                                        email: {
                                            value: "",
                                            selected: false,
                                        },
                                        contactNo: {
                                            value: "",
                                            selected: false,
                                        },
                                        address: {
                                            value: "",
                                            selected: false,
                                        },
                                        logoPos: {
                                            value: "left",
                                            selected: false,
                                        },
                                    }
                                );
                            })()}
                        />
                    )}
                    <TableContainer>
                        <Table size="medium">
                            <TableHead>
                                <TableRow style={{ whiteSpace: "nowrap" }}>
                                    {columnsVisibility.SrNo && (
                                        <TableCell>SR No</TableCell>
                                    )}
                                    {columnsVisibility.Client && (
                                        <TableCell>Client Name</TableCell>
                                    )}

                                    {columnsVisibility.Phone && (
                                        <TableCell>Phone</TableCell>
                                    )}

                                    {columnsVisibility.City && (
                                        <TableCell>City</TableCell>
                                    )}
                                    {columnsVisibility.balance && (
                                        <TableCell>Balance</TableCell>
                                    )}
                                    {columnsVisibility.collection && (
                                        <TableCell>Collection</TableCell>
                                    )}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {trialBalance?.map((item: any, idx: number) => (
                                    <TableRow>
                                        {columnsVisibility.SrNo && (
                                            <TableCell>{idx + 1}</TableCell>
                                        )}

                                        {columnsVisibility.Client && (
                                            <TableCell>
                                                {item.client.employee.name}
                                            </TableCell>
                                        )}

                                        {columnsVisibility.Phone && (
                                            <TableCell>
                                                {item?.client.employee
                                                    ?.phone ? (
                                                    item.client.employee.phone
                                                ) : (
                                                    <Typography
                                                        style={{
                                                            color: theme.palette
                                                                .divider,
                                                        }}
                                                    >
                                                        N/A
                                                    </Typography>
                                                )}
                                            </TableCell>
                                        )}

                                        {columnsVisibility.City && (
                                            <TableCell>
                                                {item?.client?.employee.city
                                                    .name ? (
                                                    item?.client?.employee.city
                                                        .name
                                                ) : (
                                                    <Typography
                                                        style={{
                                                            color: theme.palette
                                                                .divider,
                                                        }}
                                                    >
                                                        N/A
                                                    </Typography>
                                                )}
                                            </TableCell>
                                        )}

                                        {columnsVisibility.balance && (
                                            <TableCell>
                                                {currencyFormatter(
                                                    item.balance
                                                )}
                                            </TableCell>
                                        )}

                                        {columnsVisibility.collection && (
                                            <TableCell>
                                                ________________________________
                                            </TableCell>
                                        )}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            ) : (
                <Alert severity="info" style={{ width: "100%" }}>
                    No pending payments to show trial balance
                </Alert>
            )}
        </Grid>
    );
};

export default TrialBalance;
